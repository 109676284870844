<template>
  <div class="flex flex-col w-full relative h-auto min-h-[32rem]">
    <nuxt-link :to="localePath({name : 'product-slug', params : {slug : product.slug2 ? product.slug2 : ''}})"
               class="h-full flex flex-col relative rounded-card overflow-hidden bg-white shadow-product sm:hover:shadow-product-hover transition-shadow picture-hover"
               @click.native="onClick">
      <div class="gradient-top overflow-hidden relative flex-shrink-0">
        <client-only>
          <div v-if="!owner && !hideHeader" class="absolute z-[3] top-4 left-0 px-4 w-full flex justify-between items-center">
            <div class="flex w-full">
              <div class="flex w-[80%]" v-if="product?.user">
                <nuxt-link class="flex-none w-[30px] h-[30px] border border-white rounded-[30px] overflow-hidden sm:w-[40px] sm:h-[40px] sm:border-2 sm:hover:border-primary transition-colors duration-300"
                           :to="localePath({name : 'shop-pseudo', params : { pseudo : product?.user?.pseudo }})">
                  <Picture :sizes="['60:60']" class="overflow-hidden rounded-full" :src="product?.user?.image" />
                </nuxt-link>
                <div class="flex flex-col overflow-x-hidden ml-2 text-10 font-medium text-white sm:text-14">
                  <div class="flex items-center">
                    <span class="overflow-x-hidden overflow-ellipsis">{{ product?.user?.pseudo }}</span>
                  </div>
                  <div v-if="product?.user?.score > 0" class="flex items-center">
                    <RatingIcon class="flex-initial flex-shrink-0 w-4 h-4 text-primary mr-2 sm:w-6 sm:h-6"/>
                    <span>{{ product?.user?.score }}</span>
                  </div>
                </div>
              </div>

              <FavoriteToggle v-if="favorite && $auth.loggedIn"
                                  class="flex-none ml-auto w-12 h-12 p-1 sm:p-0 self-end"
                                  :product="product"
                                  :stroke="true"
                                  color-liked="text-primary"
                                  color="text-white sm:hover:text-primary"/>
            </div>
          </div>
        </client-only>
        <Picture class="relative z-[1]"
                 :key="productImage"
                 :alt="productName"
                 :sizes="['602:698']"
                 :src="productImage"
                 :rotation="productRotation"
                 :blurUp="true"/>
        <div v-if="owner"
             class="absolute z-10 bottom-4 left-4 right-4 flex justify-between items-stretch">
          <Badge :label="$t(`product.status.${visibility}.title`)"
                 :type="statusStyle"/>
          <Badge class="h-auto"
                 icon-size="w-5 h-5"
                 px="p-0"
                 :icon="visibilityStyle.icon"
                 :type="visibilityStyle.style"/>

        </div>
        <div v-if="!owner && productStatus"
             class="absolute z-10 bottom-4 left-4 right-4 flex justify-between">
          <Badge :label="productStatus.label"
                 :icon="productStatus.icon"
                 :px="productStatus.icon ? 'pl-2 pr-4' : 'px-5'"
                 icon-size="w-5 h-5"
                 :icon-color="productStatus.iconColor"
                 :type="productStatus.type"/>

        </div>
      </div>

      <div class="px-6  py-4 flex-1 flex flex-col">
        <h2 class="mr-2 text-14 leading-6 font-bold text-black truncate" v-html="brand" />
        <h3 v-if="!short" class="block text-12 text-black-light truncate" v-html="productName"/>
        <p class="text-12 font-medium text-black-light truncate" v-html="details"/>
        <PriceRow v-if="!short" :price="productPrice" small :state-id="product.stateId" />
      </div>

      <div v-if="owner" class="mx-2 flex items-center justify-center">
        <nuxt-link v-if="visibility === 9"
                   class=" text-12 font-bold text-center border border-solid rounded-lg border-gray-400 p-1 m-2 w-full"
                   :to="localePath({name : 'product-slug-update', params : {slug : product.slug2 ? product.slug2 : ''}})">
          {{ $t('product.edit') }}
        </nuxt-link>
        <overlay-form v-else class="text-12 font-bold text-center border border-solid rounded-lg border-gray-400 p-1 m-2 w-full"
                      :force-height="false"
                      type="popinV2"
                      component="remove"
                      :noStyle="true"
                      action="delete"
                      :data="{productID, visibility}" >
          {{ $t('product.delete') }}
        </overlay-form>
      </div>
      <Badge v-if="!owner && product?.badge?.label"
             class="mb-6 mx-4 mt-0"
             :label="product?.badge?.label"
             truncate
             :border="product.badge.border"
             :type="badgeType"/>

    </nuxt-link>

    <div class="absolute top-4 z-10 right-4" v-if="owner">
      <overlay-form class="w-auto flex-1"
                    :force-height="false"
                    type="popinV2"
                    component="remove"
                    :noStyle="true"
                    action="delete"
                    :data="{productID, visibility}" >
        <Thrash class="text-white w-9 h-9" />
      </overlay-form>

    </div>
  </div>
</template>

<script>

import {mapGetters} from "vuex";
import FavoriteToggle from "@/components/FavoriteToggle";
import OverlayForm from "@/components/form/OverlayForm";
import { getEcommerceItems, GTM_EVENTS } from "../assets/js/gtm-events";
import {getProductStatus, slugToID} from "assets/js/utils/product";

// Icon
import RatingIcon from "@/assets/svg/rating.svg?inline";
import BadgeIcon from "@/assets/svg/badge.svg?inline";
import Thrash from "~/assets/svg/thrash.svg?inline"
import IconButton from "@/components/buttons/IconButton";
import Badge from "@/components/global/Badge";
import {STYLE_TYPES} from "assets/js/constants/constant";
import {getUserStatus} from "assets/js/utils/user";
import moment from "moment";
import PriceRow from "@/components/product/PriceRow.vue";
import Picture from "@/components/Picture.vue";

export default {
  components: {
    Picture,
    PriceRow,
    Badge,
    Thrash,
    IconButton,
    FavoriteToggle,
    OverlayForm,
    RatingIcon,
    BadgeIcon
  },
  props: {
    favorite: {
      type: Boolean,
      default: true
    },
    product: {
      type: Object
    },
    hideHeader: {
      type: Boolean,
      default: false
    },
    gtmItemListName: {
      type: String,
      required: true,
    },
    index: {
      type: Number,
      required: true,
    },
    settings: Boolean,
    owner: Boolean,
    short: Boolean
  },
  async mounted() {
    this.$emit('ready');
    await this.buildDetails();
  },
  data() {
    return {
      details: this.$t('product.size.unique'),
    }
  },
  beforeDestroy() {
    this.details = null;
  },
  methods: {
    async buildDetails() {

      if (this.product && this.product.sizeId) {
        const size = await this.$store.dispatch('config/configSearch', {
          parent: 'sizes',
          id: this.product.sizeId
        });
        if (size) {
          this.details = this.$t(size.name);
        }
      }
    },
    async onClick() {
      this.$store.dispatch('nav/searchByID', this.product.catalogId).then(categories => {
        this.$gtm.push({
          event: GTM_EVENTS.item.select,
          item_list_name: this.gtmItemListName,
          ...getEcommerceItems([{
            id: this.productID,
            name: this.product.name,
            brand: this.product.brand.name,
            categories,
            price: this.product.price,
            quantity: 1,
            itemState: this.product.stateId,
            itemListName: this.gtmItemListName,
            index: this.index,
          }]),
        })
      })

      this.$emit('onClick')
    }
  },
  computed: {
    ...mapGetters({
      config: 'config/getConfig'
    }),
    statusStyle() {
      switch(this.visibility) {
        case 0:
          return STYLE_TYPES.error
        case 1:
          return STYLE_TYPES.primary
        case 2:
          return STYLE_TYPES.info
        case 3:
          return STYLE_TYPES.darker
        case 9:
          return STYLE_TYPES.warning

      }
    },
    visibilityStyle() {
      switch(this.visibility) {
        case 5:
        case 4:
        case 3:
        case 0:
        case 9:
          return {
            icon: 'eye-closed',
            style: STYLE_TYPES.inversedDarker
          }
        default:
          return {
            icon: 'eye',
            style: STYLE_TYPES.inversedPrimary
          }
      }
    },
    badgeType() {
      const type = STYLE_TYPES[this.product?.badge?.type]
      return type ? type : STYLE_TYPES.default
    },
    productID() {
      return this.product ? slugToID(this.product.slug2) : null;
    },
    visibility() {
      return this.product ? parseInt(this.product.visible) : 0;
    },
    isNew() {
      return this.product ? parseInt(this.product.stateId) === 1 || parseInt(this.product.stateId) === 2 : false
    },
    productName() {
      return this.product ? this.product.name : '&nbsp;'
    },
    productRotation() {
      return this.product && this.product.images_rotations && this.product.images_rotations.length ? this.product.images_rotations[0] : 0;
    },
    productImage() {
      return this.product && this.product.images && this.product.images.length ? this.product.images[0] : null;
    },
    brand() {
      return this.product && this.product.brand ? this.product.brand.name : '&nbsp;'
    },
    productPrice() {
      if (this.owner) {
        return {
          price: this.product?.price,
        }
      }
      return {
        price: this.product?.price,
        purchasePrice: this.product?.purchase_price,
        discount: this.product?.percent_discount,
        dropping: this.product?.drop_price
      }
    },
    productStatus() {
      return getProductStatus(this.product.user?.genre, this.product?.stateId, this.$i18n)
    },
    releaseDate() {
      return this.product.available_at ? moment(this.product.available_at).format('DD.MM.YYYY') : null
    }

  },
}
</script>

