<template>
  <button :aria-label="$t('profil.favorites')" :name="$t('profil.favorites')" type="button"
          :class="{'Favorite--active' : liked && animate}"
          class="Favorite flex flex-shrink-0 items-start mt-2 text-white" @click="onClick">
    <FavoriteStroke v-if="stroke && !liked" :class="color" class="w-full transition-colors duration-300"/>
    <Favorite v-else :class="(liked) ? colorLiked : color" class="w-full"/>
  </button>
</template>

<script>
import Favorite from "~/assets/svg/favorite.svg?inline";
import FavoriteStroke from "~/assets/svg/favorite-stroke.svg?inline";
import { slugToID } from "assets/js/utils/product";
import { getEcommerceItems, GTM_EVENTS } from "../assets/js/gtm-events";

export default {
  components: {
    Favorite,
    FavoriteStroke
  },
  props: {
    product: Object,
    stroke: {
      type: Boolean,
      default: false
    },
    color: {
      type: String,
      default: 'text-background-sky sm:hover:text-red-500'
    },
    colorLiked: {
      type: String,
      default: 'text-primary'
    }
  },
  data() {
    return {
      loading: false,
      animate: false,
      userAction: false,
    }
  },
  computed: {
    liked() {
      return this.$auth.user && this.productID && this.$auth.user.favoriteProductIdList2 ? this.$auth.user.favoriteProductIdList2.includes(this.productID) : false;
    },
    productID() {
      return this.product ? slugToID(this.product.slug2) : '';
    },
  },
  watch: {
    liked(value, old) {
      if(this.userAction) {

        let fakeValue = 1;

        if(value === false) {
          fakeValue = 0;
        }

        this.$emit('fake',fakeValue);
      }
      this.animate = value === true && old === false && this.userAction;
    }
  },
  methods: {
    async onClick(e) {
      e.preventDefault()
      e.stopPropagation()
      this.userAction = true;

      if (!this.loading && this.$auth.loggedIn) {
        this.loading = true;
        let response;

        if (!this.liked) {
          response = await this.$api.$put(process.env.apiUrl + '/api/v1/products/' + this.productID + '/favorite/add');
        } else {
          response = await this.$api.$delete(process.env.apiUrl + '/api/v1/products/' + this.productID + '/favorite');
        }

        if (response && !response.error) {
          this.$gtm.push({
            event: GTM_EVENTS.item.like,
            ...getEcommerceItems([{
              id: this.productID,
              name: this.product?.name,
              brand: this.product?.brand.name,
              categories: await this.$store.dispatch('nav/searchByID', this.product?.catalogId),
              price: this.product?.price,
              quantity: 1,
              itemState: this.product?.stateId,
              itemListName: null,
              index: null,
            }]),
          });
          await this.$auth.fetchUser();
        }
        this.loading = false;
      }
    }
  }
}
</script>
