export default {
  transition(to, from) {
    let transition = null

    // FROM
    const isTransitionFromEmpty = (from?.meta?.transition === undefined || from?.meta?.transition === '')
    const transitionFrom = from?.meta?.transition || ''
    const depthFrom = from?.meta?.depth || 0
    // TO
    const isTransitionToEmpty = (to?.meta?.transition === undefined || to?.meta?.transition === '')
    const transitionTo = to?.meta?.transition || ''
    const depthTo = to?.meta?.depth || 0

    // console.log('Transition from: (', from?.name, ' | trans:', transitionFrom, ' | depth:', depthFrom, ') - To: (', to?.name, ' | trans:', transitionTo, ' | depth:', depthTo, ')')

    // PAGE TO TOP (BOTTOM => TOP)
    if (transitionTo === 'page-to-top') {
      transition = 'page-to-top'
    } else if (isTransitionToEmpty && transitionFrom === 'page-to-top') {
      transition = 'page-to-top-reverse'
    }
    // PAGE TO LEFT (LEFT => RIGHT)
    else if (transitionTo === 'page-to-left') {
      // Depth is used for detecting open or close page (important when the user use the back button)
      transition = (depthTo < depthFrom) ? 'page-to-left-reverse' : 'page-to-left'
      // Custom
      if (transitionFrom === 'slide-to-left') {
        transition = 'page-to-left-reverse'
      }
    } else if (isTransitionToEmpty && transitionFrom === 'page-to-left') {
      transition = 'page-to-left-reverse'
    }
    // SLIDE
    else if (transitionTo === 'slide-to-left') {
      transition = (depthTo < depthFrom) ? 'slide-to-left-reverse' : 'slide-to-left'
      // Custom
      if (transitionFrom === 'page-to-left') {
        transition = 'page-to-left'
      }
    } else if (isTransitionToEmpty && transitionFrom === 'slide-to-left') {
      transition = 'slide-to-left-reverse'
    } else {
      transition = 'page-static'
    }

    if (transitionTo === 'no-transition') {
      transition = null
    }



    // console.log('Transition page:', transition)

    return transition
  },
}
