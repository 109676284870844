<template>
  <div class="flex flex-col">
    <div class="flex justify-between items-center">
      <p class="font-heading leading-10 flex items-center flex-shrink-0">
        <span>
          <span class="font-bold"
                :class="{'text-primary': priceDropping, 'text-14': small, 'text-24': !small}"
                v-html="defaultPrice"/>
          <span v-if="originalPrice && showDiscount"
                :class="{'text-12 ml-2': small, 'text-16 ml-4': !small}"
                class="inline-block  font-medium text-gray-600 line-through"
                v-html="originalPrice"/>
        </span>
        <Icon v-if="priceDropping"
              :class="{'w-10 mb-1 ml-4': !small, 'w-7 ml-2': small}"
              class="h-auto inline-block text-primary" icon="down" />
      </p>
      <div class="flex-shrink-0">
          <span v-if="discount && showDiscount"
                :class="{'text-10  ml-2': small, 'text-14 ml-4': !small}"
                class="flex items-center rounded-sm font-bold text-white px-2 bg-secondary-450"
                v-html="discount"/>
      </div>
    </div>
    <span class="text-13 font-heading font-medium text-surface-medium flex-shrink-1" v-if="price.priceFees">{{ $t('product.price.fees', {price: readablePriceFees}) }}</span>
  </div>
</template>

<script>
import Icon from "@/components/global/Icon.vue"

export default {
  name: "PriceRow",
  components: {Icon},
  props: {
    price: {
      type: Object,
      default: () => {}
    },
    small: Boolean,
    stateId: Number
  },
  computed: {
    defaultPrice() {
      return this.price?.price ? this.price.price + " €" : '&nbsp;';

      //return this.price?.price ? this.$n(this.price.price, 'currency', 'fr') : '&nbsp;';
    },
    originalPrice() {
      return this.price?.purchasePrice ? this.price.purchasePrice + " €" : null;
      //return this.price?.purchasePrice ? this.$n(this.price.purchasePrice, 'currency', 'fr') : null;
    },
    discount() {
      return this.price?.discount ? '- ' + this.price.discount : null
    },
    priceDropping() {
      return this.price?.dropping
    },
    readablePriceFees() {

      return this.price?.priceFees ? this.price.priceFees + " €" : null;
      //return this.price?.priceFees ? this.$n(this.price.priceFees, 'currency', 'fr') : null;
    },
    showDiscount() {
      return [1,2].includes(this.stateId)
    }
  }
}
</script>

<style scoped>

</style>
